<template>
  <div>
    <div v-if="roomProfileGifts.length > 0">
      <div class="see-all-container">
        <router-link to="gifts/all" class="link-common">
          See All
        </router-link>
      </div>
      <VgiftsPanel :gifts="roomProfileGifts" :public_view="true" />
    </div>
    <div v-else id="vgifts-empty">
      <p>Room has no gifts yet.<br>Start with yourself</p>
    </div>
    <div id="send-gift-wrapper" class="align-center">
      <Button
        type="green"
        value="Send Gift"
        :height="40"
        :width="120"
        @click="toSendGift"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import VgiftsPanel from "@/components/VgiftsPanel";
import Button from "@/components/Button";

export default {
  name: "InfoGifts",
  components: {
    Button,
    VgiftsPanel
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'roomProfile'
    ]),
    roomProfileGifts() {
      return this.roomProfile.gifts;
    }
  },
  created() {
  },
  methods: {
    toSendGift() {
      this.$helper.openURL(this.$helper.getStoreGiftsUrl(this.roomProfile.short_info.nickname));
    }
  }
}
</script>

<style scoped>
.see-all-container {
  text-align: right;
  padding: 0 40px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 35px;
}
.see-all-container a {
  position: relative;
  top: -20px;
}
#send-gift-wrapper {
  margin-bottom: 50px;
}

#vgifts-empty {
  margin: 150px auto 25px;
  text-align: center;
  color: #545454;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}
</style>
